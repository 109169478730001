import type { FunctionComponent, HTMLProps } from "react";

import AddNew from "./assets/add-new.svg";
import AdditionalApplications from "./assets/additional-applications.svg";
import AdvancedSquare from "./assets/advanced-square.svg";
import Advanced from "./assets/advanced.svg";
import AlgorithmsSquare from "./assets/algorithms-square.svg";
import Algorithms from "./assets/algorithms.svg";
import AnalysisGuidesComputer from "./assets/analysis-guides-computer.svg";
import AnalysisGuidesSquare from "./assets/analysis-guides-square.svg";
import AnalysisGuides from "./assets/analysis-guides.svg";
import AnalysisSquare from "./assets/analysis-square.svg";
import Apple from "./assets/apple.svg";
import ArrowDiag from "./assets/arrow-diag.svg";
import ArrowDown from "./assets/arrow-down.svg";
import ArrowRight from "./assets/arrow-right.svg";
import ArrowUp from "./assets/arrow-up.svg";
import BadgeCheck from "./assets/badge-check.svg";
import Bang from "./assets/bang.svg";
import BarChart from "./assets/bar-chart.svg";
import BillingSquare from "./assets/billing-square.svg";
import Billing from "./assets/billing.svg";
import Blink from "./assets/blink.svg";
import BlueCheckmark from "./assets/blue-checkmark.svg";
import Bluetooth from "./assets/bluetooth.svg";
import BookOpen from "./assets/book-open.svg";
import CheckmarkAlt from "./assets/checkmark-alt.svg";
import Checkmark from "./assets/checkmark.svg";
import Clipboard from "./assets/clipboard.svg";
import Clock from "./assets/clock.svg";
import Close from "./assets/close.svg";
import Collapse from "./assets/collapse.svg";
import CommandLine from "./assets/command-line.svg";
import CommandsSquare from "./assets/commands-square.svg";
import Commands from "./assets/commands.svg";
import Comment from "./assets/comment.svg";
import ComputerDownloadSquare from "./assets/computer-download-square.svg";
import ComputerDownload from "./assets/computer-download.svg";
import Console from "./assets/console.svg";
import Context from "./assets/context.svg";
import CopyLink from "./assets/copy-link.svg";
import CytAssist from "./assets/cytassist.svg";
import Copy from "./assets/copy.svg";
import Dash from "./assets/dash.svg";
import DatasetsSquare from "./assets/datasets-square.svg";
import Datasets from "./assets/datasets.svg";
import DatasetHumanSpecies from "./assets/datasets_human.svg";
import DatasetMouseSpecies from "./assets/datasets_mouse.svg";
import Delete from "./assets/delete.svg";
import Documentation from "./assets/documentation.svg";
import Download from "./assets/download.svg";
import Dropdown from "./assets/dropdown.svg";
import Edit from "./assets/edit.svg";
import Eject from "./assets/eject.svg";
import Email from "./assets/email.svg";
import Exit from "./assets/exit.svg";
import ExternalLink from "./assets/external-link.svg";
import Facebook from "./assets/facebook.svg";
import Faqs from "./assets/faqs.svg";
import FileAltLight from "./assets/file-alt-light.svg";
import FileAlt from "./assets/file-alt.svg";
import File from "./assets/file.svg";
import FilterCompact from "./assets/filter-compact.svg";
import Filter from "./assets/filter.svg";
import Flask from "./assets/flask.svg";
import Funnel from "./assets/funnel.svg";
import Github from "./assets/github.svg";
import GlossarySquare from "./assets/glossary-square.svg";
import GradientBlueCheck from "./assets/gradient-blue-checkmark.svg";
import Glossary from "./assets/glossary.svg";
import GraduationCap from "./assets/graduation-cap.svg";
import Grant from "./assets/grant.svg";
import Hamburger from "./assets/hamburger.svg";
import Headset from "./assets/headset.svg";
import History from "./assets/history.svg";
import Home from "./assets/home.svg";
import ImageSolid from "./assets/image-solid.svg";
import InfoSolid from "./assets/info-solid.svg";
import Info from "./assets/info.svg";
import Information from "./assets/information.svg";
import Instructions from "./assets/instructions.svg";
import Language from "./assets/language.svg";
import Layers from "./assets/layers.svg";
import Letter from "./assets/letter.svg";
import LightbulbSmall from "./assets/lightbulb-small.svg";
import Lightbulb from "./assets/lightbulb.svg";
import Linkedin from "./assets/linkedin.svg";
import Loader from "./assets/loader.svg";
import Logo from "./assets/logo.svg";
import LoupeBrowserSquare from "./assets/loupe-browser-square.svg";
import LoupeBrowser from "./assets/loupe-browser.svg";
import LoupeIcon from "./assets/loupe-icon.svg";
import Magic from "./assets/magic.svg";
import Megaphone from "./assets/megaphone.svg";
import NavDown from "./assets/nav-down.svg";
import NavLeft from "./assets/nav-left.svg";
import NavRight from "./assets/nav-right.svg";
import NavUp from "./assets/nav-up.svg";
import Newspaper from "./assets/newspaper.svg";
import Overview from "./assets/overview.svg";
import Pause from "./assets/pause.svg";
import Pdf from "./assets/pdf.svg";
import Pin from "./assets/pin.svg";
import PlayCircle from "./assets/play-circle.svg";
import Play from "./assets/play.svg";
import Plus from "./assets/plus.svg";
import Pricing from "./assets/pricing.svg";
import ProjectCart from "./assets/project-cart.svg";
import Publications from "./assets/publications.svg";
import QuestionAlt from "./assets/question-alt.svg";
import QuestionBubbleSquare from "./assets/question-bubble-square.svg";
import QuestionBubble from "./assets/question-bubble.svg";
import Question from "./assets/question.svg";
import XeniumRangerAnalysisBody from "./assets/ranger_analysis_body.svg";
import XeniumRangerAnalysisNav from "./assets/ranger_analysis_nav.svg";
import ReleaseNotesSquare from "./assets/release-notes-square.svg";
import ReleaseNotes from "./assets/release-notes.svg";
import Remove from "./assets/remove.svg";
import Reset from "./assets/reset.svg";
import Rocket from "./assets/rocket.svg";
import Search from "./assets/search.svg";
import Security from "./assets/security.svg";
import Settings from "./assets/settings.svg";
import Sort from "./assets/sort.svg";
import StarAlt from "./assets/star-alt.svg";
import Star from "./assets/star.svg";
import SupportedProductSquare from "./assets/supported-product-square.svg";
import Target from "./assets/target.svg";
import Troubleshooting from "./assets/troubleshooting-square.svg";
import Tutorials from "./assets/tutorials.svg";
import Twitter from "./assets/twitter.svg";
import Unhappy from "./assets/unhappy.svg";
import Unverified from "./assets/unverified.svg";
import Usb from "./assets/usb.svg";
import UserAdd from "./assets/user-add.svg";
import UserGroup from "./assets/user-group.svg";
import UserGuides from "./assets/user-guides.svg";
import User from "./assets/user.svg";
import Video from "./assets/video.svg";
import Videos from "./assets/videos.svg";
import Wallet from "./assets/wallet.svg";
import Warning from "./assets/warning.svg";
import Wifi from "./assets/wifi.svg";
import Windows from "./assets/windows.svg";
import Wrong from "./assets/wrong.svg";
import XeniumAnalyzerInstrument from "./assets/xenium-analyzer-instrument.svg";
import XeniumAnalyzer from "./assets/xenium-analyzer.svg";
import XeniumExplorer from "./assets/xenium-explorer.svg";
import XeniumRanger from "./assets/xenium-ranger.svg";
import XeniumOnboardAnalysisBody from "./assets/xenium_onboard_analysis_body.svg";
import XeniumOnboardAnalysisNav from "./assets/xenium_onboard_analysis_nav.svg";
import Youtube from "./assets/youtube.svg";
import Names from "./names";

export type IconProps = HTMLProps<HTMLOrSVGElement>;

export const icons: Record<string, FunctionComponent<IconProps>> = {
  [Names.AddNew]: AddNew,
  [Names.AdditionalApplications]: AdditionalApplications,
  [Names.Advanced]: Advanced,
  [Names.AdvancedSquare]: AdvancedSquare,
  [Names.Algorithms]: Algorithms,
  [Names.AlgorithmsSquare]: AlgorithmsSquare,
  [Names.AnalysisSquare]: AnalysisSquare,
  [Names.AnalysisGuides]: AnalysisGuides,
  [Names.AnalysisGuidesSquare]: AnalysisGuidesSquare,
  [Names.AnalysisGuidesComputer]: AnalysisGuidesComputer,
  [Names.ArrowDiag]: ArrowDiag,
  [Names.ArrowDown]: ArrowDown,
  [Names.ArrowRight]: ArrowRight,
  [Names.ArrowUp]: ArrowUp,
  [Names.Apple]: Apple,
  [Names.BadgeCheck]: BadgeCheck,
  [Names.Bang]: Bang,
  [Names.BarChart]: BarChart,
  [Names.Blink]: Blink,
  [Names.Bluetooth]: Bluetooth,
  [Names.BookOpen]: BookOpen,
  [Names.Billing]: Billing,
  [Names.BillingSquare]: BillingSquare,
  [Names.BlueCheckmark]: BlueCheckmark,
  [Names.Checkmark]: Checkmark,
  [Names.CheckmarkAlt]: CheckmarkAlt,
  [Names.Clipboard]: Clipboard,
  [Names.Clock]: Clock,
  [Names.Close]: Close,
  [Names.Collapse]: Collapse,
  [Names.Commands]: Commands,
  [Names.CommandsSquare]: CommandsSquare,
  [Names.CommandLine]: CommandLine,
  [Names.Comment]: Comment,
  [Names.ComputerDownload]: ComputerDownload,
  [Names.ComputerDownloadSquare]: ComputerDownloadSquare,
  [Names.Console]: Console,
  [Names.Context]: Context,
  [Names.Copy]: Copy,
  [Names.CopyLink]: CopyLink,
  [Names.CytAssist]: CytAssist,
  [Names.Dash]: Dash,
  [Names.DatasetHumanSpecies]: DatasetHumanSpecies,
  [Names.DatasetMouseSpecies]: DatasetMouseSpecies,
  [Names.Datasets]: Datasets,
  [Names.DatasetsSquare]: DatasetsSquare,
  [Names.Delete]: Delete,
  [Names.Documentation]: Documentation,
  [Names.Download]: Download,
  [Names.Dropdown]: Dropdown,
  [Names.Edit]: Edit,
  [Names.Eject]: Eject,
  [Names.Email]: Email,
  [Names.Exit]: Exit,
  [Names.ExternalLink]: ExternalLink,
  [Names.Facebook]: Facebook,
  [Names.Faqs]: Faqs,
  [Names.File]: File,
  [Names.FileAlt]: FileAlt,
  [Names.FileAltLight]: FileAltLight,
  [Names.Filter]: Filter,
  [Names.FilterCompact]: FilterCompact,
  [Names.Flask]: Flask,
  [Names.Funnel]: Funnel,
  [Names.Github]: Github,
  [Names.Glossary]: Glossary,
  [Names.GlossarySquare]: GlossarySquare,
  [Names.GradientBlueCheck]: GradientBlueCheck,
  [Names.GraduationCap]: GraduationCap,
  [Names.Grant]: Grant,
  [Names.Hamburger]: Hamburger,
  [Names.Headset]: Headset,
  [Names.History]: History,
  [Names.Home]: Home,
  [Names.ImageSolid]: ImageSolid,
  [Names.Info]: Info,
  [Names.InfoSolid]: InfoSolid,
  [Names.Information]: Information,
  [Names.Instructions]: Instructions,
  [Names.Language]: Language,
  [Names.Layers]: Layers,
  [Names.Letter]: Letter,
  [Names.Linkedin]: Linkedin,
  [Names.Lightbulb]: Lightbulb,
  [Names.LightbulbSmall]: LightbulbSmall,
  [Names.Loader]: Loader,
  [Names.Logo]: Logo,
  [Names.LoupeBrowser]: LoupeBrowser,
  [Names.LoupeBrowserSquare]: LoupeBrowserSquare,
  [Names.LoupeIcon]: LoupeIcon,
  [Names.Magic]: Magic,
  [Names.Megaphone]: Megaphone,
  [Names.NavRight]: NavRight,
  [Names.NavDown]: NavDown,
  [Names.NavLeft]: NavLeft,
  [Names.NavUp]: NavUp,
  [Names.Newspaper]: Newspaper,
  [Names.Overview]: Overview,
  [Names.Pause]: Pause,
  [Names.Pdf]: Pdf,
  [Names.Pin]: Pin,
  [Names.Play]: Play,
  [Names.PlayCircle]: PlayCircle,
  [Names.Plus]: Plus,
  [Names.Pricing]: Pricing,
  [Names.ProjectCart]: ProjectCart,
  [Names.Publications]: Publications,
  [Names.ReleaseNotes]: ReleaseNotes,
  [Names.ReleaseNotesSquare]: ReleaseNotesSquare,
  [Names.Rocket]: Rocket,
  [Names.Question]: Question,
  [Names.QuestionAlt]: QuestionAlt,
  [Names.QuestionBubble]: QuestionBubble,
  [Names.QuestionBubbleSquare]: QuestionBubbleSquare,
  [Names.Remove]: Remove,
  [Names.Reset]: Reset,
  [Names.Search]: Search,
  [Names.Security]: Security,
  [Names.Settings]: Settings,
  [Names.Sort]: Sort,
  [Names.Star]: Star,
  [Names.StarAlt]: StarAlt,
  [Names.SupportedProductSquare]: SupportedProductSquare,
  [Names.Target]: Target,
  [Names.Troubleshooting]: Troubleshooting,
  [Names.Tutorials]: Tutorials,
  [Names.Twitter]: Twitter,
  [Names.Unhappy]: Unhappy,
  [Names.Unverified]: Unverified,
  [Names.Usb]: Usb,
  [Names.User]: User,
  [Names.UserAdd]: UserAdd,
  [Names.UserGroup]: UserGroup,
  [Names.UserGuides]: UserGuides,
  [Names.Video]: Video,
  [Names.Videos]: Videos,
  [Names.Wallet]: Wallet,
  [Names.Warning]: Warning,
  [Names.Wifi]: Wifi,
  [Names.Windows]: Windows,
  [Names.Wrong]: Wrong,
  [Names.XeniumAnalyzer]: XeniumAnalyzer,
  [Names.XeniumAnalyzerInstrument]: XeniumAnalyzerInstrument,
  [Names.XeniumExplorer]: XeniumExplorer,
  [Names.XeniumRangerAnalysisBody]: XeniumRangerAnalysisBody,
  [Names.XeniumRangerAnalysisNav]: XeniumRangerAnalysisNav,
  [Names.XeniumOnboardAnalysisBody]: XeniumOnboardAnalysisBody,
  [Names.XeniumOnboardAnalysisNav]: XeniumOnboardAnalysisNav,
  [Names.XeniumRanger]: XeniumRanger,
  [Names.Youtube]: Youtube,
};
